//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import BuildFilterQS from '../../helpers/BuildFilterQS';
import config from '../../config/config';
import {searchService} from './search';

import comparisonMainData from './stubs/track_comparison';
import comparisonDemographicsData from './stubs/track_comparison_demographics';

export const tracksService = {
    getTracks,
    getTopTracks,
    getTopTracksInPlaylist,
    getTrackDetails,
    getTracksAutocomplete,
    getCard,
    getStreamsStats,
    getMetadata,
    getMetadataByISRCS,
    getTimeseriesTracks,
    getTimeseriesTracksInPlaylist,
    compareTracks,
    compareTrackDemographics,
    compareTrackTerritories,
    compareTrackVendors,
    getTracksReleaseDates,
    getTiktokTimeseries,
    getTiktokTerritories,
    getTiktokTerritoriesTimeseries,
    getTiktokMetrics,
    compareTrackArtist,
    compareProductTracks
};

function getTracksOld(params) {
    let { sorted, page, pageSize, filtered } = params;
    const requestOptions = {
        method: 'GET',
    };
    
    // TODO: API dependent, change for prod
    let baseUrl = `${config.apis.sales}/api/v1/Track`, 
        qs = AuthHeader();
    
    if(sorted !== undefined)
        qs.sort = {[sorted[0].id]: sorted[0].desc?-1:1};
    if(page !== undefined && pageSize !== undefined ) {
        qs.limit = pageSize;
        qs.skip = pageSize*params.page;
    }
    if(filtered !== undefined ) {
        let filters = [];
        for(let filter of filtered){
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array){
                if(value.length)
                    filters.push(`"${filter.id}": {"$in": [${value.join(',')}]}`);
            }
            else if(typeof value === 'string')
                filters.push(`"${filter.id}": {"$regex": "${value}"}`);
            else
                filters.push(`"${filter.id}": ${value}`);    
            
        }
        if(filters.length)
            qs.query = `{${filters.join(',')}}`; 
    }
    //
    baseUrl += '?' + Param(qs);

    let pages;
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        pages = res.headers.get('X-Total-Count');   
        return res.json()
    })
    .then(data=>{
        return {data, pages}
    });
}

function getTracks(params, globalFilter, basic){
    return  config.live ? getTracksLive(params, globalFilter, basic) : getTracksStatic(basic);
}

function getTracksLive(params, globalFilter, basic = true) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };        
    
    qs.query_name = `cat_nav_tracks${basic?'_basic':''}`;
    qs.offset = params.page ? params.page*globalFilter.limit : 0;
    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else if(typeof value === 'string') { // TODO: kinda crappy way of determining full-string search 
                qs.search_by = filter.id;
                qs.search_term = filter.value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }
    qs = BuildFilterQS(qs, 'tracks');
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getTracksStatic(basic) { 
    let baseUrl = `${config.apis.sales}/api/static/tracks${basic?'_basic':''}.json`;
    return fetch(baseUrl)
    .then(res=>{   
        return res.json()
    });
}


function getTopTracks(params, globalFilter, fields){
    return config.live ? getTopTracksLive(params, globalFilter, fields) : getTopTracksStatic();
}

function getTopTracksLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'top_tracks';
    // qs.offset = 0;

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }        
    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    qs = BuildFilterQS(qs, 'tracks');
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTopTracksStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_tracks_simple.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getTopTracksInPlaylist(params, globalFilter, fields){
    return config.live ? getTopTracksInPlaylistLive(params, globalFilter, fields) : getTopTracksInPlaylistStatic();
}

function getTopTracksInPlaylistLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'top_tracks_in_playlist';
    // qs.offset = 0;

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }        

    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTopTracksInPlaylistStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_tracks_in_playlist_simple.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTrackDetails(mode) {
    let baseUrl = `${config.apis.sales}/api/static/track_products.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTracksAutocomplete(input) {
    return searchService.autocomplete(input, ['tracks']);
}

function getCard(id) {
    let fields = 'id,title,packshot_file_name', 
        baseUrl = `${config.apis.miniCat}/v2/tracks/${id}?fields=${fields}`,
        requestOptions = {
            headers: AuthHeader()
        };
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getStreamsStats(ids, filtered, globalFilter) {
    return config.live ? getStreamsStatsLive(ids, filtered, globalFilter) : getStreamsStatsStatic();
}

function getStreamsStatsLive(ids, extFiltered, globalFilter) {

    //
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [...extFiltered, {id:'ids', value:ids}]
    }
    //
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'top_tracks';
    // qs.offset = params.page ? params.page*globalFilter.limit : 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    baseUrl += '?' + Param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
} 

function getStreamsStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/tracks_streams.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    })    
}

function getMetadata(ids){
    return true || config.live ? getMetadataLive(ids) : getMetadataStatic();
}

function getMetadataLive(ids) {
    ids = encodeURIComponent(ids.join(','));
    if(!ids) {
        return Promise.resolve({data: []});
    }
    
    let baseUrl = `${config.apis.miniCat}/v2/tracks/by_id/`,
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}),
            body: 'track_ids='+ids
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getMetadataStatic() {
    let baseUrl = `${config.apis.sales}/api/static/tracks_metadata.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}

function getMetadataByISRCS(ids) {
    ids = encodeURIComponent(ids.join(','));
    if(!ids) {
        return Promise.resolve({data: []});
    }
    
    let baseUrl = `${config.apis.miniCat}/v2/tracks/by_isrc/?track_isrc=${ids}`,
        requestOptions = {
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'}),
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getTimeseriesTracks(params, globalFilter){
    return config.live ? getTimeseriesTracksLive(params, globalFilter) : getTimeseriesTracksStatic();
}

function getTimeseriesTracksLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'top_tracks';
    // qs.offset = 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered, dateGroup} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }
    if(dateGroup) {
        qs.date_grouping = dateGroup;
    }


    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTimeseriesTracksStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_tracks_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}



function getTimeseriesTracksInPlaylist(params, globalFilter){
    return config.live ? getTimeseriesTracksInPlaylistLive(params, globalFilter) : getTimeseriesTracksInPlaylistStatic();
}

function getTimeseriesTracksInPlaylistLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'top_tracks_in_playlist';
    // qs.offset = 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }        

    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTimeseriesTracksInPlaylistStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/top_tracks_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function compareTracks(ids, weeks, releaseDates, territories, vendors, user){
    return config.live ? compareTracksLive(ids, weeks, releaseDates, territories, vendors, user) : compareTracksStatic();
}

function compareTracksLive(ids, weeks, releaseDates, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = Array.isArray(ids) ? ids.join(',') : ids;
    qs.release_dates = Array.isArray(releaseDates) ? releaseDates.join(',') : releaseDates;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'main';
    qs.object = 'track';

    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareTracksStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/track_comparison.json`;
    return fetch(baseUrl).then(res=>res.json());
}

/*
function compareTracksStatic() {
    return Promise.resolve(comparisonMainData);
}
*/

function compareTrackDemographics(id, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareTrackDemographicsLive(id, weeks, releaseDate, territories, vendors, user) : compareTrackDemographicsStatic();
}

function compareTrackDemographicsLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = id;
    qs.release_dates = releaseDate;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'demographics';
    qs.object = 'track';
    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareTrackDemographicsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/track_comparison_demographics.json`;
    return fetch(baseUrl).then(res=>res.json());
}

/*
function compareTrackDemographicsStatic() {
    return Promise.resolve(comparisonDemographicsData);
}
*/


function compareTrackTerritories(id, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareTrackTerritoriesLive(id, weeks, releaseDate, territories, vendors, user) : compareTrackTerritoriesStatic();
}

function compareTrackTerritoriesLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = id;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.release_dates = releaseDate;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'territories';
    qs.object = 'track';
    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareTrackTerritoriesStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/track_comparison_territories.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function compareTrackVendors(id, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareTrackVendorsLive(id, weeks, releaseDate, territories, vendors, user) : compareTrackVendorsStatic();
}

function compareTrackVendorsLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = id;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.release_dates = releaseDate;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'vendors';
    qs.object = 'track';
    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareTrackVendorsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/track_comparison_vendors.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getTracksReleaseDates(ids) {
    ids = Array.isArray(ids) ? ids.join(',') : ids;
    let baseUrl = `${config.apis.miniCat}/v3/tracks/release_dates?isrcs=${ids}`,
        requestOptions = {
            headers: AuthHeader()
        };
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}


function getTiktokTimeseries(params, globalFilter){
    return config.live ? getTiktokTimeseriesLive(params, globalFilter) : getTiktokTimeseriesStatic();
}

function getTiktokTimeseriesLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'social_timeseries';
    // qs.offset = 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }        

    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTiktokTimeseriesStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/tiktok_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTiktokTerritories(params, globalFilter){
    return config.live ? getTiktokTerritoriesLive(params, globalFilter) : getTiktokTerritoriesStatic();
}

function getTiktokTerritoriesLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'social_territories';
    // qs.offset = 0;
    qs.mode = 'main';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }        

    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTiktokTerritoriesStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/tiktok_territories.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTiktokTerritoriesTimeseries(params, globalFilter){
    return config.live ? getTiktokTerritoriesTimeseriesLive(params, globalFilter) : getTiktokTerritoriesTimeseriesStatic();
}

function getTiktokTerritoriesTimeseriesLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'social_territories';
    // qs.offset = 0;
    qs.mode = 'timeseries';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }        

    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTiktokTerritoriesTimeseriesStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/tiktok_territories_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getTiktokMetrics(params, globalFilter){
    return config.live ? getTiktokMetricsLive(params, globalFilter) : getTiktokMetricsStatic();
}

function getTiktokMetricsLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'social_metrics';
    // qs.offset = 0;
    qs.mode = 'main';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }        

    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTiktokMetricsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/tiktok_metrics.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function compareTrackArtist(id, artistID, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareTrackArtistLive(id, artistID, weeks, releaseDate, territories, vendors, user) : compareTrackArtistStatic();
}

function compareTrackArtistLive(id, artistID, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = id;
    qs.artist_id = artistID;
    
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.release_dates = releaseDate;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'album_artist';
    qs.object = 'track';
    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareProductTracks(ids, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareProductTracksLive(ids, weeks, releaseDate, territories, vendors, user) : compareProductTracksStatic();
}

function compareProductTracksLive(ids, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = ids;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.release_dates = releaseDate;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'tracks_in_product';
    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareTrackArtistStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/album_artist.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function compareProductTracksStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/product_comparison_tracks.json`;
    return fetch(baseUrl).then(res=>res.json());
}