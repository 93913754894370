import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import PercentageValue from '../../common/PercentageValue';
import AbsoluteValue from '../../common/AbsoluteValue';
import VendorLogo from '../../common/VendorLogo';
import VendorHelper from '../../common/Datagrid/VendorHelper';
import FieldsSelectorHelper from '../../common/Datagrid/FieldsSelectorHelper';
import VendorValue from '../../common/Datagrid/VendorValue';
import PlaylistAnalysisLink from '../../common/Datagrid/PlaylistAnalysisLink';
import AudienceAnalysisLink from '../../common/Datagrid/AudienceAnalysisLink';
import EntityStatus from '../../common/Datagrid/EntityStatus';
import DetailsLink from '../../common/Datagrid/DetailsLink';
import AccountSplitBox from '../../common/Datagrid/AccountSplitBox';
import AudioFormatSplitBox from '../../common/Datagrid/AudioFormatSplitBox';
import Tooltip from '../../common/Tooltip';
import VendorFooter from '../../common/Datagrid/VendorFooter';
import { avgFooter, convertSumFooter, textFooter, spaceFooter } from '../../common/Datagrid/Footers';

var heartFullSvg = require('!svg-inline-loader!../../../../public/img/heart-full.svg');
var ghostFullSvg = require('!svg-inline-loader!../../../../public/img/ghost-full.svg');
var expandVendorSvg = require('!svg-inline-loader!../../../../public/img/expand-vendor.svg');

export const imprintsSettings = {
    settingsForPercentageChart,
    settingsForTable
};

function settingsForPercentageChart(){
    return {};
}

function settingsForTable(enabledColumns, onTitleClick, sources, expandSource){
    const mainSourceExpanded = sources ? sources['Main'] : true;
    const mainExpandClass = `${mainSourceExpanded ? 'vendor-expanded':''}`;

    let columns = [
          {
              id: 'favhide',
              className: 'logo-sell frozen favhide',
              headerClassName: 'logo-sell frozen favhide no-sort',
              headerAlt: 'Favorite/Hidden',
              width: 80,
              sortable: false,
              required: true,
              Header: "",
              Cell: props => <div className="action-icons">
                  <EntityStatus entity="imprints" id={props.original.id} />
                  <PlaylistAnalysisLink  title="Imprint Playlist Analysis" entity="imprints" id={props.original.id}></PlaylistAnalysisLink>
                  <AudienceAnalysisLink title="Imprint Audience" entity="imprints" id={props.original.id}></AudienceAnalysisLink>
              </div>,
              Footer: spaceFooter
          
          },
                  
            {
                id: 'logo',
                className: 'logo-sell frozen',
                headerClassName: 'logo-sell frozen no-sort',
                Header: 'Logo',
                accessor: 'image',
                width: 60,
                sortable: false,
                required: true,
                Cell: props => {
                    // const onClickCall = (e)=>{return onTitleClick(props.original);};
                    return <div className="logo-container"><Link to={`/imprints/${props.original.id}`} ><div className="logo" style={{backgroundImage: `url(${props.original.image})`}} />
                        </Link></div>;
                },
                Footer: spaceFooter
            },
            {
                id: 'imprint_name',
                className: 'imprint-name frozen',
                headerClassName: 'imprint-name frozen no-sort',
                Header: 'Imprint',
                accessor: 'name',
                sortable: false,
                required: true,
                width: 260,
                Cell: props => {
                    const onClickCall = (e)=>{return onTitleClick(props.original);};
                    return <span>{props.original.id ? <Link to={`/imprints/${props.original.id}`}>{props.value}</Link> : props.value}</span>
                    //return <span>{props.value}</span>;
                },
                Footer: textFooter
            },
            /*
            {
                id: 'links',
                className: 'lowercase grid-td',
                headerClassName: 'table-playlist-link no-sort',
                Header: 'Actions',
                sortable: false,
                Cell: props => <div className="action-icons">
                    <PlaylistAnalysisLink  title="Imprint Playlist Analysis" entity="imprints" id={props.original.id}></PlaylistAnalysisLink>
                    <DetailsLink title="Imprint Details" entity="imprints" id={props.original.id}></DetailsLink>
                    <AudienceAnalysisLink title="Imprint Audience" entity="imprints" id={props.original.id}></AudienceAnalysisLink>
                </div>
                // width: 105
            },
            */
            {
                id: 'curr_units',
                className: 'lowercase grid-td',
                Header: <p><span onClick={(e)=>expandSource('Main', e)} className={`vendor-group-title ${mainExpandClass}`}>Total<span className="expand-vendor-icon" dangerouslySetInnerHTML={{__html: expandVendorSvg}}></span></span></p>,
                headerAlt: "Total",
                Cell: props => <PercentageValue field="units" item={props.original}></PercentageValue>,
                accessor: 'curr_units',
                isTotalExpanded: mainSourceExpanded,
                Footer: convertSumFooter
                // width: 105
            },
            {
                id: 'skipped_ratio',
                accessor: 'skipped_ratio',
                className: 'lowercase grid-td',
                headerAlt: 'Skip Rate',
                Header: props => <div className="top-table-header-inner">
                    Skip Rate A/V
                    <div className="table-header-tooltip">
                        <Tooltip position="bottom" message={`The Skip rate only applies to streams over 30 seconds. It's a metric provided by all DSPs except YouTube and Google. A = Audio, V = Video`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>,
                Cell: ({original}) => <span>{original.audio_skipped_ratio}% / {original.video_skipped_ratio}%</span>,
                expanded: mainSourceExpanded,
                // width: 135,
                Footer: (props)=><React.Fragment>{avgFooter(props, null, 'audio_skipped_ratio')} / {avgFooter(props, null, 'video_skipped_ratio')}</React.Fragment>
            },
            {
                id: 'completed_ratio',
                accessor: 'completed_ratio',
                className: 'lowercase grid-td',
                headerAlt: 'Completion Rate',
                Header: props => <div className="top-table-header-inner">
                    Completion Rate A/V
                    <div className="table-header-tooltip">
                        <Tooltip position="right-bottom" message={`A track is marked as complete when the stream reached 95% of the track length. It's a metric provided by all DSPs except YouTube and Google. A = Audio, V = Video`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>,
                Cell: ({original}) => <span>{original.audio_completed_ratio}% / {original.video_completed_ratio}%</span>,
                // width: 135,
                expanded: mainSourceExpanded,
                Footer: (props)=><React.Fragment>{avgFooter(props, null, 'audio_completed_ratio')} / {avgFooter(props, null, 'video_completed_ratio')}</React.Fragment>
            },
            /*
            {
                id: 'completed_diff',
                headerClassName: 'table-header-item no-sort',
                Header: props => <div className="top-table-header-inner">
                    Ratio S/C 
                    <Tooltip 
                        position="bottom" 
                        message={`S = Skips / C = Completion\n
                        These metrics only apply to streams over 30 seconds. A track is marked as complete when the stream reaches 95% of the track length.\n
                        They are provided by all DSPs except Google and YouTube`} 
                        tooltipClass="toolbar-title-tooltip" />
                    </div>,
                accessor: 'completed_diff',
                headerAlt: 'Ratio S/C',
                Cell: props => <div>
                <span title="Skipped">{props.original.skipped_ratio}%</span>
                /
                <span title="Completed">{props.original.completed_ratio}%</span>
                </div>,
                 
                sortable: false,
                // width: 130
            },
            */
            {
                id: 'sub30',
                Header: props => <div className="top-table-header-inner">
                    Sub30 
                    <div className="table-header-tooltip">
                        <Tooltip position="bottom" message={`Sub30 refers to streams that did not hit the 30â€� mark and therefore are excluded from the total streams and skips count as non-royalty bearing metric. This is currently only available from Spotify.`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>,
                accessor: 'sub30',
                headerAlt: 'Sub30',
                Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
                expanded: mainSourceExpanded,
                Footer: convertSumFooter
                // width: 134
            },
            {
                id: 'saves_ratio',
                Header: 'Saves',
                accessor: 'saves_ratio',
                Cell: props => <span title="Saves">{props.value}%</span>,
                expanded: mainSourceExpanded,
                Footer: avgFooter
                // width: 134
            },

            
            {
                id: 'accounts',
                className: 'account-td',
                headerClassName: 'no-sort',
                sortable: false,
                headerAlt: 'Account Type',
                sortable: false,
                Header: props => <div className="top-table-header-inner">
                    Accounts F/D/P 
                    <div className="table-header-tooltip">
                        <Tooltip position="bottom" message={`F = Freemium / D = Discounted / P = Premium \nThese are provided by all DSPs except YouTube.\nNote that Spotify only provide Freemium or Premium type, so all Discounted accounts as under Premium`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>,
                Cell: props => <AccountSplitBox item={props.original} />,
                expanded: mainSourceExpanded,
                // width: 135,
                Footer: (props)=><React.Fragment>{avgFooter(props, null, 'free_ratio')} / {avgFooter(props, null, 'discounted_ratio')} / {avgFooter(props, null, 'premium_ratio')}</React.Fragment>
            },
            /*
            {
                id: 'audio_formats',
                className: 'account-td',
                headerClassName: 'no-sort',
                headerAlt: 'Audio Format Type',
                Header: props => <div className="top-table-header-inner">
                    Audio Format C/L/I 
                    <div className="table-header-tooltip">
                        <Tooltip position="bottom" message={`Audio format represents the quality of the audio streamed and is available from Apple and Amazon only.\nC = compressed (mp3 and other formats)\nL = lossless (CD quality and high res)\nI = immersive (spatial and 360 audio)`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>,
                Cell: props => <AudioFormatSplitBox item={props.original} />,
                expanded: mainSourceExpanded,
                width: 120
            },
            */
            {
                id: 'audio_compressed',
                className: 'account-td',
                headerClassName: 'no-sort',
                sortable: false,
                headerAlt: "Compressed",
                Header: props => <div className="top-table-header-inner">
                    Compressed 
                    <div className="table-header-tooltip">
                        <Tooltip position="bottom" message={`Audio format represents the quality of the audio streamed and is available from Apple and Amazon only.\nCompressed - mp3 and other formats`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>,
                accessor: 'audio_compressed',
                Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
                expanded: mainSourceExpanded,
                // width: 110,
                Footer: convertSumFooter
            },            
            {
                id: 'audio_lossless',
                className: 'account-td',
                headerClassName: 'no-sort',
                sortable: false,
                headerAlt: "Lossless",
                Header: props => <div className="top-table-header-inner">
                    Lossless 
                    <div className="table-header-tooltip">
                        <Tooltip position="bottom" message={`Audio format represents the quality of the audio streamed and is available from Apple and Amazon only.\nLossless - CD quality and high res`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>,
                accessor: 'audio_lossless',
                Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
                expanded: mainSourceExpanded,
                Footer: convertSumFooter
            },
            {
                id: 'audio_immersive',
                className: 'account-td',
                headerClassName: 'no-sort',
                headerAlt: "Immersive",
                sortable: false,
                Header: props => <div className="top-table-header-inner">
                    Immersive 
                    <div className="table-header-tooltip">
                        <Tooltip position="bottom" message={`Audio format represents the quality of the audio streamed and is available from Apple and Amazon only.\nImmersive - spatial and 360 audio`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>,
                accessor: 'audio_immersive',
                Cell: props => <AbsoluteValue value={props.value}></AbsoluteValue>,
                expanded: mainSourceExpanded,
                Footer: convertSumFooter
            },                        
            {
                id: 'discovery_ratio',
                Header: props => <div className="top-table-header-inner">
                    Discovery 
                    <div className="table-header-tooltip">
                        <Tooltip position="bottom" message={`Discovery Flag shows you the % of total streams which were a first listen from a user. This is provided by Apple and Spotify only.`} tooltipClass="toolbar-title-tooltip"/>
                    </div>
                </div>,
                accessor: 'discovery_ratio',
                headerAlt: 'Discovery',
                Cell: props => <span title="Discovery">{props.value}%</span>,
                expanded: mainSourceExpanded,
                // width: 120,
                Footer: avgFooter
            },           
            {
                id: 'curr_us_ratio',
                className: 'lowercase grid-td',
                Header: 'US Streams',
                accessor: 'curr_us_ratio',
                Cell: props => <span title="US Streams">{props.value}%</span>,
                expanded: mainSourceExpanded,
                Footer: avgFooter
                // width: 105
            },
            {
                id: 'curr_non_us_ratio',
                className: 'lowercase grid-td',
                Header: 'Non-US Streams',
                accessor: 'curr_non_us_ratio',
                Cell: props => <span title="Non-US Streams">{props.value}%</span>,
                expanded: mainSourceExpanded,
                Footer: avgFooter
                // width: 105
            },       
            {
                id: 'curr_youtube_ratio',
                className: 'lowercase grid-td',
                Header: 'YouTube Streams',
                accessor: 'curr_youtube_ratio',
                Cell: props => <span title="YouTube Streams">{props.value}%</span>,
                expanded: mainSourceExpanded,
                Footer: avgFooter
                // width: 105
            },       
            {
                id: 'curr_playlist_streams_ratio',
                className: 'lowercase grid-td',
                Header: 'Playlist Streams',
                accessor: 'curr_playlist_streams_ratio',
                Cell: props => <span title="Playlist Streams">{props.value}%</span>,
                expanded: mainSourceExpanded,
                Footer: avgFooter
                // width: 105
            },
            {
                id: 'engaged_ratio',
                className: 'lowercase grid-td',
                Header: 'Engaged',
                accessor: 'engaged_ratio',
                Cell: props => <span title="Engaged">{props.value}%</span>,
                expanded: mainSourceExpanded,
                Footer: avgFooter
                // width: 105
            },       
            
            /*
            {
                id: 'curr_listeners',
                className: 'lowercase grid-td listeners',
                Header: 'Listeners',
                Cell: props => <PercentageValue field="listeners" item={props.original}></PercentageValue>,
                // width: 105
            },
            */
            /*            
            {
                id: 'curr_playlists',
                className: 'lowercase grid-td',
                Header: 'Playlists',
                Cell: props => <PercentageValue field="playlists" item={props.original}></PercentageValue>,
                width: 105
            },
            {
                id: 'curr_products',
                className: 'lowercase grid-td',
                Header: 'Products',
                Cell: props => <PercentageValue field="products" item={props.original}></PercentageValue>,
                width: 105
            },
            {
                id: 'curr_tracks',
                className: 'lowercase grid-td',
                Header: 'Tracks',
                Cell: props => <PercentageValue field="tracks" item={props.original}></PercentageValue>,
                width: 105
            },
            */
            /*{
                id: 'stream',
                className: 'lowercase grid-td total-td',
                headerClassName: 'total-td no-sort',
                Header: 'Stream',
                sortable: false,
                // width: 525,
                Cell: props => <TrendsBox name="Stream" trends={props.original.trends}></TrendsBox>
            },
            {
                id: 'video',
                className: 'lowercase grid-td total-td',
                headerClassName: 'total-td no-sort',
                Header: 'Video',    
                sortable: false,
                // width: 310,            
                Cell: props => <TrendsBox name="Video" trends={props.original.trends}></TrendsBox>
            },                      
            {
                id: 'radio',
                className: 'lowercase grid-td total-td',
                headerClassName: 'total-td no-sort',
                Header: 'Radio',    
                sortable: false,
                // width: 310,            
                Cell: props => <TrendsBox name="Radio" trends={props.original.trends}></TrendsBox>
            },
            {
                id: 'downloads',
                className: 'lowercase grid-td total-td',
                headerClassName: 'total-td no-sort',
                Header: 'Downloads',    
                sortable: false,
                // width: 310,            
                Cell: props => <TrendsBox name="Downloads" trends={props.original.trends}></TrendsBox>
            }
            }*/                        

        ]

    for(let source of VendorHelper.VendorSources()){
        const sourceExpanded = sources ? sources[source] : true;
        let column = VendorHelper.VendorDistribution(source);
        const vendorsQty = column.length;
        const expandClass = `${sourceExpanded ? 'vendor-expanded':''}`;
        for(let vendorID = 0; vendorID < vendorsQty; vendorID++) {
            let vendor = column[vendorID];
            const noSortClassName = vendor.accessor == 'total' ? '' : 'no-sort';
            const firstChildClassName = vendorID == 0 ? 'first' : '';
            const totalVendor = vendor.accessor == 'total' ? true : false;

            vendor.Header = props => vendor.accessor == 'total'?<span onClick={()=>expandSource(source)} className={`vendor-group-title ${expandClass}`}>{source}<span className="expand-vendor-icon" dangerouslySetInnerHTML={{__html: expandVendorSvg}}></span></span>:<VendorLogo name={vendor.accessor} modificator="small" />;
            vendor.headerClassName = `header-${source.toLowerCase().replace(' ', '-')} ${noSortClassName} ${firstChildClassName} no-sort`;
            vendor.sortable = false;
            vendor.Cell = props => <div onClick={()=> totalVendor ? expandSource(source) : undefined}><VendorValue source={source} vendor={vendor.accessor} trends={props.original.trends} /></div>
            vendor.className = `vendor-cell vendor-cell-${vendor.accessor.toLowerCase().replace(/\s/g, "")}${vendorID==0?' first':''} ${source.toLowerCase().replace(' ', '')}`;
            vendor.expanded = vendor.accessor == 'total' ? true : sourceExpanded;
            vendor.Footer = data => <VendorFooter source={source} vendor={vendor.accessor} data={data} />
        }

        columns = columns.concat(column);
    }
    
    return FieldsSelectorHelper.filterFields(columns, enabledColumns); 
}
