import { tracksConstants } from '../constants/tracks';

export function tracks(state = {track: {}, card: {}, topPlaylist: {}, 
        topPlaylistTimeseries: {}, autoCompleteLoading: false, 
        demographicsComparison: {}, territoriesComparison: {}, 
        vendorsComparison: {}, artistComparison: {}, productTracksComparison: {}}, 
        action) {
    switch (action.type) {
        case tracksConstants.GET_TRACKS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case tracksConstants.GET_TRACKS_SUCCESS:
            return {

                ...state,
                loading: false,
                items: action.tracks,
                metadata: action.metadata,
                total: action.total
            };
        case tracksConstants.GET_TRACKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case tracksConstants.GET_TOP_TRACKS_REQUEST:
            return {
                ...state,
                topLoading: true,
                top: null,
                metadata: null,
                timeseries: null
            };
        case tracksConstants.GET_TOP_TRACKS_SUCCESS:
            return {

                ...state,
                topLoading: false,
                top: action.tracks,
                metadata: action.metadata
            };
        case tracksConstants.GET_TOP_TRACKS_FAILURE:
            return {
                ...state,
                topLoading: false,
                error: action.error
            };

        case tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_REQUEST:
            return {
                ...state,
                topPlaylist: {
                    ...state.topPlaylist,
                    [action.playlistID]: {
                        loading: true
                    }
                }
            };
        case tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_SUCCESS:
            return {

                ...state,
                topPlaylist: {
                    ...state.topPlaylist,
                    [action.playlistID]: {
                        loading: false,
                        data: action.tracks,
                        metadata: action.metadata
                    }
                }

            };
        case tracksConstants.GET_TOP_TRACKS_IN_PLAYLIST_FAILURE:
            return {

                ...state,
                topPlaylist: {
                    ...state.topPlaylist,
                    [action.playlistID]: {
                        loading: false
                    }
                }

            };
            
        case tracksConstants.GET_TIMESERIES_TRACKS_IN_PLAYLIST_REQUEST:
            return {
                ...state,
                topPlaylistTimeseriesLoading: true,
                topPlaylistTimeseries: null
            };
        case tracksConstants.GET_TIMESERIES_TRACKS_IN_PLAYLIST_SUCCESS:
            return {

                ...state,
                topPlaylistTimeseriesLoading: false,
                topPlaylistTimeseries: action.tracks,

            };
        case tracksConstants.GET_TIMESERIES_TRACKS_IN_PLAYLIST_FAILURE:
            return {

                ...state,
                topPlaylistTimeseriesLoading: false

            };
            
            
        case tracksConstants.GET_TRACKS_AUTOCOMPLETE_REQUEST:
            return {
                ...state,
                autocomplete_items: [],
                autoCompleteLoading: true
            };
        case tracksConstants.GET_TRACKS_AUTOCOMPLETE_SUCCESS:
            return {

                ...state,
                autoCompleteLoading: false,
                autocomplete_items: action.tracks
            };
        case tracksConstants.GET_TRACKS_AUTOCOMPLETE_FAILURE:
            return {
                ...state,
                autoCompleteLoading: false,
                error: action.error
            };
        case tracksConstants.GET_TRACK_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case tracksConstants.GET_TRACK_DETAILS_SUCCESS:
            let track = state.track;
            track[action.id] = action.track;
            return {
                ...state,
                loading: false,
                track: track
            };
        case tracksConstants.GET_TRACK_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };
        case tracksConstants.GET_TRACKS_CARD_REQUEST:
            return {
                ...state,
                cardLoading: true
            };
        case tracksConstants.GET_TRACKS_CARD_SUCCESS:
            let card = state.card;
            card[action.id] = action.card;
            return {
                ...state,
                cardLoading: false,
                card: card
            };
        case tracksConstants.GET_TRACKS_CARD_FAILURE:
            return {
                ...state,
                cardLoading: false,
                error: action.error
            };

        case tracksConstants.GET_STREAMS_STATS_REQUEST:
            return {
                ...state,
                streamsLoading: true,
                streams: undefined
            };
        case tracksConstants.GET_STREAMS_STATS_SUCCESS:
            return {
                ...state,
                streamsLoading: false,
                streams: action.tracks
            };
        case tracksConstants.GET_STREAMS_STATS_FAILURE:
            return {
                ...state,
                streamsLoading: false,
                error: action.error,
                streams: undefined
                
            };

        case tracksConstants.GET_TIMESERIES_TRACKS_REQUEST:
            return {
                ...state,
                timeseriesLoading: true,
                timeseries: null
            };
        case tracksConstants.GET_TIMESERIES_TRACKS_SUCCESS:
            return {

                ...state,
                timeseriesLoading: false,
                timeseries: action.tracks
            };
        case tracksConstants.GET_TIMESERIES_TRACKS_FAILURE:
            return {
                ...state,
                timeseriesLoading: false,
                error: action.error
            };

        case tracksConstants.COMPARE_TRACKS_REQUEST:
            return {
                ...state,
                comparisonLoading: true
            };
        case tracksConstants.COMPARE_TRACKS_SUCCESS:
            return {

                ...state,
                comparisonLoading: false,
                comparison: action.tracks,
            };
        case tracksConstants.COMPARE_TRACKS_FAILURE:
            return {
                ...state,
                comparisonLoading: false,
                error: action.error
            };

        case tracksConstants.COMPARE_TRACK_DEMOGRAPHICS_REQUEST:
            return {
                ...state,
                demographicsComparisonLoading: true
            };
        case tracksConstants.COMPARE_TRACK_DEMOGRAPHICS_SUCCESS:
            return {

                ...state,
                demographicsComparisonLoading: false,
                demographicsComparison: {
                    ...state.demographicsComparison,
                    [action.id]: action.track
                }
                    
                    
            };
        case tracksConstants.COMPARE_TRACK_DEMOGRAPHICS_FAILURE:
            return {
                ...state,
                demographicsComparisonLoading: false,
                error: action.error
            };

        case tracksConstants.COMPARE_TRACK_TERRITORIES_REQUEST:
            return {
                ...state,
                territoriesComparisonLoading: true
            };
        case tracksConstants.COMPARE_TRACK_TERRITORIES_SUCCESS:
            return {

                ...state,
                territoriesComparisonLoading: false,
                territoriesComparison: {
                    ...state.territoriesComparison,
                    [action.id]: action.track
                }
                    
                    
            };
        case tracksConstants.COMPARE_TRACK_TERRITORIES_FAILURE:
            return {
                ...state,
                territoriesComparisonLoading: false,
                error: action.error
            };
            
        case tracksConstants.COMPARE_TRACK_VENDORS_REQUEST:
            return {
                ...state,
                vendorsComparisonLoading: true
            };
        case tracksConstants.COMPARE_TRACK_VENDORS_SUCCESS:
            return {

                ...state,
                vendorsComparisonLoading: false,
                vendorsComparison: {
                    ...state.vendorsComparison,
                    [action.id]: action.track
                }
                    
                    
            };
        case tracksConstants.COMPARE_TRACK_VENDORS_FAILURE:
            return {
                ...state,
                vendorsComparisonLoading: false,
                error: action.error
            };

        case tracksConstants.GET_TIKTOK_TIMESERIES_REQUEST:
                return {
                    ...state,
                    tiktokTimeseriesLoading: true,
                    tiktokTimeseries: null
                };
        case tracksConstants.GET_TIKTOK_TIMESERIES_SUCCESS:
            return {
        
                ...state,
                tiktokTimeseriesLoading: false,
                tiktokTimeseries: action.tracks
            };
        case tracksConstants.GET_TIKTOK_TIMESERIES_FAILURE:
            return {
                ...state,
                tiktokTimeseriesLoading: false,
                error: action.error
            };

        case tracksConstants.GET_TIKTOK_TERRITORIES_REQUEST:
            return {
                ...state,
                tiktokTerritoriesLoading: true,
                tiktokTerritories: null
            };
        case tracksConstants.GET_TIKTOK_TERRITORIES_SUCCESS:
            return {
        
                ...state,
                tiktokTerritoriesLoading: false,
                tiktokTerritories: action.tracks
            };
        case tracksConstants.GET_TIKTOK_TERRITORIES_FAILURE:
            return {
                ...state,
                tiktokTerritoriesLoading: false,
                error: action.error
            };

        case tracksConstants.GET_TIKTOK_TERRITORIES_TIMESERIES_REQUEST:
            return {
                ...state,
                tiktokTerritoriesTimeseriesLoading: true,
                tiktokTerritoriesTimeseries: null
            };
        case tracksConstants.GET_TIKTOK_TERRITORIES_TIMESERIES_SUCCESS:
            return {
        
                ...state,
                tiktokTerritoriesTimeseriesLoading: false,
                tiktokTerritoriesTimeseries: action.tracks
            };
        case tracksConstants.GET_TIKTOK_TERRITORIES_TIMESERIES_FAILURE:
            return {
                ...state,
                tiktokTerritoriesTimeseriesLoading: false,
                error: action.error
            };

        case tracksConstants.GET_TIKTOK_METRICS_REQUEST:
            return {
                ...state,
                tiktokMetricsLoading: true,
                tiktokMetrics: null
            };
        case tracksConstants.GET_TIKTOK_METRICS_SUCCESS:
            return {
        
                ...state,
                tiktokMetricsLoading: false,
                tiktokMetrics: action.tracks
            };
        case tracksConstants.GET_TIKTOK_METRICS_FAILURE:
            return {
                ...state,
                tiktokMetricsLoading: false,
                error: action.error
            };

        case tracksConstants.COMPARE_PRODUCT_TRACKS_REQUEST:
            return {
                ...state,
                productTracksComparisonLoading: true
            };
        case tracksConstants.COMPARE_PRODUCT_TRACKS_SUCCESS:
            return {
        
                ...state,
                productTracksComparisonLoading: false,
                productTracksComparison: {
                    ...state.productTracksComparison,
                    [action.productId]: action.tracks
                }
                    
                    
            };
        case tracksConstants.COMPARE_PRODUCT_TRACKS_FAILURE:
            return {
                ...state,
                productTracksComparisonLoading: false,
                error: action.error
            };

            
        case tracksConstants.COMPARE_TRACK_ARTIST_REQUEST:
            return {
                ...state,
                artistComparisonLoading: true
            };
        case tracksConstants.COMPARE_TRACK_ARTIST_SUCCESS:
            return {
        
                ...state,
                artistComparisonLoading: false,
                artistComparison: {
                    ...state.artistComparison,
                    [action.id]: action.track
                }
                    
                    
            };
        case tracksConstants.COMPARE_TRACK_ARTIST_FAILURE:
            return {
                ...state,
                artistComparisonLoading: false,
                error: action.error
            };
            
            
        default:
            return state
    }
}
