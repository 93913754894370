import { encrypt } from '../../helpers/crypto';
import config from '../../config/config';
import moment from 'moment';
import { getWeekNumber } from '../helpers/heartbeats'

export const sharedPagesFormatter = {
    formatSharedPages,
    formatSharedUser
};

function sharedPageSummary(page) {
    const { module, filter_data: filterData } = page;
    switch(module) {
        case 'home': 
            return `${filterData.dateStart} - ${filterData.dateEnd}`;
        case 'reporting':
            const reportDate = new Date(page.entity_id);
            return `Week ${getWeekNumber(reportDate)}, ${reportDate.getFullYear()}`;
        case 'track_comparison':
            return '';

        default:
            return 'tbd';
    }
}

function formatSharedPages(data, user) {
    for(let item of data) {
        item.title = item.title || '-- share title TBD --';
        item.module = item.module || 'catalog';
        item.summary = sharedPageSummary(item);
    }
    return data.filter(item=> item.user_id == user.id);
}

function formatSharedUser(user){
    user.sharedToken = true;
    // token is encrypted for share domain
    return encrypt(JSON.stringify(user), config.apis.shared);
}