import React from 'react';
import moment from 'moment';
import {merge, concat, pickBy, uniq, filter, sortBy, find, cloneDeep } from 'lodash';
import { ExportToCsv } from 'export-to-csv';
import XLSX from 'xlsx';
import whitelist from './CSVExport.json';
import {dateRangeFlat} from './DateRange';

const timelineKeys = ['period', 'stream_date', 'curr_units'];
const complexKeys = {
  "curr_units": ["prev_units"],
  "accounts": ["free","discounted","premium"],
  "skipped_ratio": ["audio_skipped_ratio", "video_skipped_ratio"],
  "completed_ratio": ["audio_completed_ratio", "video_completed_ratio"],
  "release_date": ["original_release_date"],
  "discovery_ratio": ["discovery"]
}

export default {
    CSVExport,
    XLSExport,
    CSVHeader,
    CSVBulkExport,
    expandTrends,
    expandTimeline,
    expandTimelineFlat,
    filterColumns
}

function prepareData(data, entity) {
    if(!data)
        return null;
    
    data = data.map(item=>pickBy(item, (v,k)=>(v===null || typeof v!=='object')));
    let keys = [],
        sortedData = [],
        entityWhitelist = [];
    
    if(entity && whitelist[entity]!==undefined) {
        entityWhitelist = concat(whitelist[entity], whitelist.common);
    }    
    for(let item of data) {
        for(let key of Object.keys(item)) {
            if(entityWhitelist.length && !entityWhitelist.includes(key)) {
                delete item[key];
            }
            else {
                if(key.endsWith('_date') && Number.isInteger(item[key]))
                    item[key] = moment(item[key]).format('YYYY-MM-DD');
                if(key=='upc')
                    item[key] = String(item[key]);
                keys.push(key);
            }
            /*
            if(item[key]===null)
                item[key]='';
            */
        }
    }
    keys = uniq(keys);
    
    keys = sortBy(keys, (key)=>entityWhitelist.indexOf(key));
        
    for(let item of data) {
        let newItem = {};
        for(let key of keys) {
          if(key.startsWith('prev_')) {
            let currKey = key.replace('prev_', 'curr_');
            if(item[currKey] == item[key]) {
              newItem[key]=0;
              continue;
            }
          }
          if(!item.hasOwnProperty(key)) {
            newItem[key]='';
          }
          else {
            newItem[key] = item[key];
          }
        }
        sortedData.push(newItem);
    }

    return sortedData;
}

function CSVBulkExport(data, filename) {
    let wb =  XLSX.utils.book_new();
    data.forEach((entity, index) => {
        const entityName = Object.keys(entity)[0];
        const entityValues = Object.values(entity)[0];
        const sheetData = prepareData(entityValues, entityName);
        if(sheetData !== null) {
            let ws = XLSX.utils.json_to_sheet(sheetData);
            XLSX.utils.book_append_sheet(wb, ws, entityName);
        }
    });
    XLSX.writeFile(wb, `${filename}.xlsx`); 
    
}

function CSVBulkExportOld(data) {
  const SEPARATOR = "\r\n";

  let csv = "data:text/csv;charset=utf-8,";
  data.forEach((entity, index) => {
    const entityName = Object.keys(entity)[0];
    const entityValues = Object.values(entity)[0];
    const headers = whitelist[entityName] ? whitelist[entityName] : Object.keys(entityValues[0]);
    const headersRow = headers.join(',') + SEPARATOR;

    csv += headersRow;
    entityValues.forEach(entityItem => {
      var result = {};
        for (let key in entityItem){
          if (headers.indexOf(key) > -1){
            result[key] = entityItem[key];
          }
        }
      const values = Object.values(result);
      csv += values.join(',') + SEPARATOR;
    });
  });
  const encodedUri = encodeURI(csv);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", "data.csv");
  document.body.appendChild(link);

  link.click();
}

function CSVExport(data, params={}, entity=null){
    const options = merge({
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
      }, params);

    const csvExporter = new ExportToCsv(options);
    data = prepareData(data, entity);
    csvExporter.generateCsv(data);
}

function XLSExport(data, params, entity=null) {
    data = prepareData(data, entity);
    let wb =  XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, entity);
    XLSX.writeFile(wb, `${params.filename}.xlsx`); 
    
}

function CSVHeader(entity, sort, filter, parentEntityTitle=null){
    let header = [entity];
    if(sort!==null)
        header.push(sort);
    if(parentEntityTitle)
        header.unshift(parentEntityTitle);

    if(filter) {
        header.push(moment(filter.dateStart).format('YYYYMMDD'));
        header.push(moment(filter.dateEnd).format('YYYYMMDD'));
    }
    return header.join('-');
}

function expandTrends(data) {
    for(let item of data) {
        if(item.trends) {
            for(let trend of item.trends) {
                let key = trend.content_type;
                if(trend.vendor)
                  key = `${key}.${trend.vendor}`;
                item[key]=trend.curr_units;
            }
        }
    }
    
    return data;
}

function expandTimeline(data, timelineKey='stms_by_date') {
  console.log(data);
    let expandedData = [];
    for(let item of data) {
        const timeline = item[timelineKey];
        for(let dayIndex in timeline) {
            const day = timeline[dayIndex];
            let row = {};
            for(let key of Object.keys(item)) {
                const value = (dayIndex == 0)? item[key] : '';
                row[key] = value;
            }
            for(let key of timelineKeys) {
                const rowKey = key == 'curr_units' ? 'units' : key;
                row[rowKey] = day[key];
            }
            expandedData.push(row);
        }
    }
    
    return expandedData;
    
    
}

function expandTimelineFlat(data, timeseries, IDKey) {
    let expandedData = [];
    
    for(let item of data) {
        let streams = filter(timeseries, (stream=>stream[IDKey]==item[IDKey]));
        const {dates, period} = dateRangeFlat(streams);
        for(let dateIndex in dates) {
            const date = dates[dateIndex];
            const stream = find(streams, {stream_date: date});
            
            let row = {};
            for(let key of Object.keys(item)) {
                const value = (dateIndex == 0)? item[key] : '';
                row[key] = value;
            }
            for(let key of timelineKeys) {
                const rowKey = key == 'curr_units' ? 'units' : key;
                row[rowKey] = stream[key];
            }
            expandedData.push(row);
        }
    }
    return expandedData;
    
    
}

function filterColumns(data, columns, initialColumns) {
  let allInitialColumns = cloneDeep(initialColumns);
  let allColumns = cloneDeep(columns);
  for(let complexKey of Object.keys(complexKeys)) {
    allInitialColumns = allInitialColumns.concat(complexKeys[complexKey]);
    if(allColumns.includes(complexKey))
      allColumns = allColumns.concat(complexKeys[complexKey])
  } 
  let result = [];
  for(let row of data) {
    let filtered = {};
    for(let key of Object.keys(row)) {
      if(!allInitialColumns.includes(key.toLowerCase()) || allColumns.includes(key.toLowerCase()))
        filtered[key] = row[key];
    }
    result.push(filtered);
  }
  return result;
}