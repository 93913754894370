//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import BuildFilterQS from '../../helpers/BuildFilterQS';
import config from '../../config/config';

export const statsService = {
    getTopStats,
    getCatalogStats,
    getVendorStats,
    getVendorTimeseries,
    getTopTerritoryStats,
    getTopTerritoryTimeseries,
    getTerritoryStats,
    getTerritoryTimeseries,
    getTerritoryInPlaylistStats,
    getTerritoryInPlaylistTimeseries,    
    getDemographicsStats,
    getDemographicsTimeseries,
    getDevicesStats,
    getDevicesTimeseries,
    getSourcesStats,
    getSourcesDiscoveryStats,
    getSourcesTimeseries,
    getSourcesDiscoveryTimeseries,
    getMultiEntitiesStats,
    getLocationStats
};

function getTopStats(globalFilter, params, listeners = false){
    return config.live ? getTopStatsLive(globalFilter, params, listeners) : getTopStatsStatic(listeners);
}

function getTopStatsLive(globalFilter, params, listeners) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };        
    qs.query_name = false ? 'catalogue_overview_listeners' : 'catalogue_overview';
    // // qs.offset = 0;
    let {filtered} = params;

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }
    
       
    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTopStatsStatic(listeners) {
    const file = listeners ? 'catalog_overview_listeners' : 'catalog_overview';
    let baseUrl = `${config.apis.sales}/api/static/${file}.json`;    
    return fetch(baseUrl).then(res=>res.json());
}

function getVendorStats(params, globalFilter, fields){
    return config.live ? getVendorStatsLive(params, globalFilter, fields) : getVendorStatsStatic();
}

function getVendorTimeseries(params, globalFilter){
    return config.live ? getVendorTimeseriesLive(params, globalFilter) : getVendorTimeseriesStatic();
}

function getTopTerritoryStats(params, globalFilter, fields){
    return config.live ? getTopTerritoryStatsLive(params, globalFilter, fields) : getTopTerritoryStatsStatic();
}

function getTopTerritoryTimeseries(params, limit, globalFilter, dateGroup){
    return config.live ? getTopTerritoryTimeseriesLive(params, limit, globalFilter, dateGroup) : getTopTerritoryTimeseriesStatic();
}


function getTerritoryStats(params, globalFilter, fields){
    return config.live ? getTerritoryStatsLive(params, globalFilter, fields) : getTerritoryStatsStatic();
}

function getTerritoryTimeseries(params, globalFilter){
    return config.live ? getTerritoryTimeseriesLive(params, globalFilter) : getTerritoryTimeseriesStatic();
}

function getTerritoryInPlaylistStats(params, globalFilter, fields){
    return config.live ? getTerritoryInPlaylistStatsLive(params, globalFilter, fields) : getTerritoryInPlaylistStatsStatic();
}

function getTerritoryInPlaylistTimeseries(params, globalFilter){
    return config.live ? getTerritoryInPlaylistTimeseriesLive(params, globalFilter) : getTerritoryInPlaylistTimeseriesStatic();
}


function getDemographicsStats(params, globalFilter){
    return config.live ? getDemographicsStatsLive(params, globalFilter) : getDemographicsStatsStatic();
}

function getDemographicsTimeseries(params, globalFilter){
    return config.live ? getDemographicsTimeseriesLive(params, globalFilter) : getDemographicsTimeseriesStatic();
}


function getDevicesStats(params, globalFilter, fields){
    return config.live ? getDevicesStatsLive(params, globalFilter, fields) : getDevicesStatsStatic();
}

function getSourcesStats(params, globalFilter, fields){
    return config.live ? getSourcesStatsLive(params, globalFilter, fields) : getSourcesStatsStatic();
}

function getSourcesDiscoveryStats(params, globalFilter, fields){
    return config.live ? getSourcesDiscoveryStatsLive(params, globalFilter, fields) : getSourcesDiscoveryStatsStatic();
}

function getSourcesTimeseries(params, globalFilter){
    return config.live ? getSourcesTimeseriesLive(params, globalFilter) : getSourcesTimeseriesStatic();
}

function getSourcesDiscoveryTimeseries(params, globalFilter){
    return config.live ? getSourcesDiscoveryTimeseriesLive(params, globalFilter) : getSourcesDiscoveryTimeseriesStatic();
}

function getDevicesTimeseries(params, globalFilter){
    return config.live ? getDevicesTimeseriesLive(params, globalFilter) : getDevicesTimeseriesStatic();
}


function getVendorStatsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'top_vendors';
    // // qs.offset = 0;
    qs.mode = 'main';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }
    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }
    
    qs.row_limit = 50;
    qs = BuildFilterQS(qs, 'vendors');
    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getVendorTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
    
    qs.query_name = 'top_vendors';
    // qs.offset = 0;
    qs.mode = 'timeseries';

    let {sorted, filtered, dateGroup} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }
    
    if(dateGroup) {
        qs.date_grouping = dateGroup;
    }
    
    qs.row_limit = 50;
    
    qs = BuildFilterQS(qs, 'products');
    baseUrl += '?' + Param(qs);

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTopTerritoryStatsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        }        
    qs.query_name = 'top_territories';
    // qs.offset = 0;

    let {sorted, limit, dateGroup} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    if(limit !== undefined){
        qs.row_limit = limit;
    }
    if(dateGroup) {
        qs.date_grouping = dateGroup;
    }

    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }
    
    qs = BuildFilterQS(qs, 'territories');
    baseUrl += '?' + Param(qs);

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTopTerritoryTimeseriesLive(ids, limit, globalFilter, dateGroup) {
    //
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    //

    
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        }        
    qs.query_name = 'top_territories';
    // qs.offset = 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    if(limit !== undefined){
        qs.row_limit = limit;
    }
    
    if(dateGroup) {
        qs.date_grouping = dateGroup;
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }        
    
    if(limit !== undefined){
        qs.row_limit = limit;
    }

    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getTerritoryStatsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'top_territories';
    // qs.offset = 0;

    let {sorted, filtered, limit} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(limit !== undefined){
        qs.row_limit = limit;
    }

    for(let param of Object.keys(params.filtered)) {
        let value = params.filtered[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }
    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }
    
    baseUrl += '?' + Param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}

function getTerritoryTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'top_territories';
    // qs.offset = 0;
    qs.mode = 'timeseries';

    let {sorted, filtered, limit, dateGroup} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(limit !== undefined){
        qs.limit = limit;
    }
    if(dateGroup) {
        qs.date_grouping = dateGroup;
    }
    
    for(let param of Object.keys(params.filtered)) {
        let value = params.filtered[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    
    baseUrl += '?' + Param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}

function getTerritoryInPlaylistStatsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'top_territories_in_playlist';
    // qs.offset = 0;

    let {sorted, filtered, limit} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(limit !== undefined){
        qs.row_limit = limit;
    }
    
    for(let param of Object.keys(params.filtered)) {
        let value = params.filtered[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    
    baseUrl += '?' + Param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}

function getTerritoryInPlaylistTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'top_territories_in_playlist';
    // qs.offset = 0;
    qs.mode = 'timeseries';

    let {sorted, filtered, limit, dateGroup} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(limit !== undefined){
        qs.limit = limit;
    }
    
    if(dateGroup) {
        qs.date_grouping = dateGroup;
    }
    
    for(let param of Object.keys(params.filtered)) {
        let value = params.filtered[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    
    baseUrl += '?' + Param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}



function getDemographicsStatsLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        } ;
    
    qs.query_name = 'detail_demographics';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.row_limit = 50;
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            

    baseUrl += '?' + Param(qs);

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getDemographicsTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        } ;
    
    qs.query_name = 'detail_demographics';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.mode = 'timeseries';
    qs.row_limit = 50;
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            

    baseUrl += '?' + Param(qs);

    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}


function getDevicesStatsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'detail_devices';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.row_limit = 50;
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    baseUrl += '?' + Param(qs);
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}

function getSourcesStatsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'detail_sources';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.row_limit = 50;
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }
    
    
    baseUrl += '?' + Param(qs);
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}

function getSourcesDiscoveryStatsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'detail_sources_discovery';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.row_limit = 50;
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    baseUrl += '?' + Param(qs);
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}


function getSourcesTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'detail_sources';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.mode = 'timeseries';
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    
    baseUrl += '?' + Param(qs);
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}

function getSourcesDiscoveryTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'detail_sources_discovery';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.mode = 'timeseries';
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    
    baseUrl += '?' + Param(qs);
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}


function getDevicesTimeseriesLive(params, globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'detail_devices';
    // qs.offset = 0;
    qs.order_by = 'curr_units';
    qs.order_dir = 'desc';
    qs.mode = 'timeseries';
    
    for(let param of Object.keys(params)) {
        let value = params[param];
        if(!value)
            continue;
        if(value instanceof Array) {
            value = value.join(',');
            if(!value)
                continue;
            qs[param] = value;
        }
        else {
            qs[param] = value;    
        }
    }            
    
    baseUrl += '?' + Param(qs);
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}



function getCatalogStats(globalFilter){
    return config.live ? getCatalogStatsLive(globalFilter) : getCatalogStatsStatic();
}

function getCatalogStatsLive(globalFilter) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };    
    qs.query_name = 'cat_nav_header';
       
    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getCatalogStatsStatic(mode) {
    let baseUrl = `${config.apis.sales}/api/static/catalog_stats.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getVendorStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_vendors_main.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getVendorTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_vendors_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTopTerritoryStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/territory_simple.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTopTerritoryTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/territory_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTerritoryInPlaylistStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_territories_in_playlist.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTerritoryInPlaylistTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_territories_in_playlist_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getTerritoryStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/territory_simple.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getTerritoryTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/territory_details_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getDemographicsStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/playlist_demographics.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getDemographicsTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/demographics_timline.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getDevicesStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/playlist_devices.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getSourcesStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/playlist_sources.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getSourcesDiscoveryStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/playlist_sources.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getSourcesTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sources_timeline.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getSourcesDiscoveryTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/sources_discovery_timeline.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function getDevicesTimeseriesStatic() {
    let baseUrl = `${config.apis.sales}/api/static/device_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getMultiEntitiesStats(filtered, globalFilter) {
    return config.live ? getMultiEntitiesStatsLive(filtered, globalFilter) : getMultiEntitiesStatsStatic();
}

function getMultiEntitiesStatsLive(extFiltered, globalFilter) {

    //
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [...extFiltered]
    }
    //
    
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'top_imprints';
    // qs.offset = params.page ? params.page*globalFilter.limit : 0;
    qs.mode = 'timeseries_ids_combined';

    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }
    
    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }    
    
    baseUrl += '?' + Param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
} 
function getMultiEntitiesStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/timeseries_combined.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function getLocationStats(params, globalFilter, fields, top){
    return config.live ? getLocationStatsLive(params, globalFilter, fields) : getLocationStatsStatic(top);
}

function getLocationStatsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    } ;

    qs.query_name = 'location';
    // qs.offset = 0;

    let {sorted, filtered, limit} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }
    
    if(limit !== undefined){
        qs.row_limit = limit;
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;    
            }
        }            
    }
    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    
    baseUrl += '?' + Param(qs).replace(/%2C/g,',');
    
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());

}

function getLocationStatsStatic(top) {
    let baseUrl = `${config.apis.sales}/api/static/${top?'top_locations':'location'}.json`;
    return fetch(baseUrl).then(res=>res.json());
}
