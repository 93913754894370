import React from 'react';
import ReactTooltip from 'react-tooltip';

class Option extends React.Component {
    constructor(props){
        super(props);
    }
    getCheckedClass(){
        if(this.props.type=='checkbox')
            return this.props.selected ? 'checkbox checked' : 'checkbox';
        else
            return this.props.selected ? 'radio checked' : 'radio';
    }
    
    renderTooltip(tooltip, id){
        return <div className="inline-filter-tooltip ">
            <span data-tip data-for={id} className="toolbar-title-tooltip-icon"> 
                <i className="fas fa-question-circle" /> 
            </span>
            <ReactTooltip effect="solid" multiline={true} place="bottom" className="toolbar-title-tooltip" id={id}>
                {tooltip}
            </ReactTooltip>
        </div>
    }

    
    render (){
        const { label, value, selected, tooltip, children, revertChildren } = this.props; 
        return  <div className={`option option-item ${this.getCheckedClass()}`}>
            <span className={this.getCheckedClass()} onClick={()=>this.props.handleChange(value)}></span>
            {revertChildren ? <React.Fragment>
                <span className="option-label" onClick={()=>this.props.handleChange(value)}>{label}</span>
                {tooltip && this.renderTooltip(tooltip, label)}
                {children}
            </React.Fragment> : <React.Fragment>
                {children}
                <span className="option-label" onClick={()=>this.props.handleChange(value)}>{label}</span>
                {tooltip && this.renderTooltip(tooltip, label)}
            </React.Fragment>}
            
        </div>;
    }
}


export default Option;