import React from 'react';
import {Helmet} from 'react-helmet';
import Progress from '../common/Progress';
import NavigationMain from '../common/NavigationMain';
import Footer from '../common/Footer';
import TopHeader from '../common/TopHeader';
import ErrorBox from '../common/ErrorBox';
import WhiteLabelCss from '../common/WhiteLabelCss';
import Filter from '../widgets/filter/Filter';
import ChatWidget from '../widgets/chat/ChatWidget';
import UserTablesLoader from '../widgets/userTables/UserTablesLoader';
import MainRoutes from '../../config/routes/main';
import WithPermission from '../../helpers/WithPermission';
import withSiloCode from '../../helpers/WithSiloCode';
import SearchWidget from '../pages/home/SearchWidget';
import { withRouter } from 'react-router';
import MatchMediaWrapper from '../../helpers/MatchMediaWrapper';
import MissingDataWarning from '../widgets/missingData/MissingDataWarning';

import { correctHeight, detectBody } from './Helpers';

class Main extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            modalExpanded: false,
            isFilterOpened: false,
            filterDataCount: null,
        }
    } 
    

    searchResult = (item) => {
        this.props.history.push(`/${item.entity}/${item.id}`);
    }
    
    onExpand = (modalExpanded) => {
        this.setState({modalExpanded});        
    }

    toggleFilterOpened = () => {
        this.setState({
            isFilterOpened: !this.state.isFilterOpened
        });        
    }

    getFilterCount = (filterCount) => {
        this.setState({
            filterDataCount: filterCount
        })
    }
    
    render() {
        const route = this.props.location.pathname,
            wrapperClass = route + (this.state.modalExpanded ? ' search-modal-expanded' : '');
        // const desktopNavigation = <NavigationMain location={this.props.location}/>
        const searchWidget = <div className="main-search-holder">
            <SearchWidget searchResult={this.searchResult} placeholder="Search all entities.." onExpand={this.onExpand} searchDropdownMode="single" />
        </div>
        const { filterDataCount } = this.state;
        const pathname = window.location.pathname;
        const pathnameRegExp = /admin|user|main|comparison/;
        const isFilderDisabled = pathnameRegExp.test(pathname);
        const notIsMain = pathname != '/main' ? true : false;
        const notIsMissingData = pathname != '/data-status' ? true : false;
        const notIsUserFilter = pathname != '/user/entities-filter/new' ? true : false;
        
        return (
            <div id="wrapper" className={this.props.silo}>
                <Progress />
                <ErrorBox />
                <WhiteLabelCss silo={this.props.silo} />
                <Helmet defaultTitle="Enterlytics" titleTemplate="%s - Enterlytics" /> 
                <div id="page-wrapper" className={`page-wrapper ${wrapperClass}`}>
                    <TopHeader 
                        // isFilterOpened={this.state.isFilterOpened}
                        // toggleFilterOpened={this.toggleFilterOpened}
                        // filterCount={filterDataCount}
                    >
                        { notIsMain && 
                        <MatchMediaWrapper isMobile={false} breakpoint={639}>
                            {searchWidget}
                        </MatchMediaWrapper> }
                        <Filter 
                            // filterClass={!isFilderDisabled ? "" : "is-main"}
                            isFilterOpened={this.state.isFilterOpened}
                            toggleFilterOpened={this.toggleFilterOpened}
                            callBackFromMain={this.getFilterCount}
                        />
                    </TopHeader>
                    {notIsMissingData && <div className="missing-data-main-holder">
                        <MissingDataWarning />
                    </div>}
                    <UserTablesLoader route={route} >
                        <div className="main-content-wrapper">
                            { notIsMain && notIsUserFilter && 
                                <MatchMediaWrapper isMobile={true} breakpoint={639}>
                                    {searchWidget}
                                </MatchMediaWrapper>
                            }
                            <MainRoutes />  
                        </div>
                        <WithPermission permission="allow_support">
                            <ChatWidget />
                        </WithPermission>
                    </UserTablesLoader>
                    <Footer />
                </div>

            </div>
        )
    }

    componentDidMount() {

        // Run correctHeight function on load and resize window event
        $(window).bind("load resize", function() {
            // correctHeight();
            detectBody();
        });

        // Correct height of wrapper after metisMenu animation.
        // $('.metismenu a').click(() => {
        //     setTimeout(() => {
        //         correctHeight();
        //     }, 300)
        // });
    }
}

export default withSiloCode(withRouter(Main))
