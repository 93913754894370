//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import config from '../../config/config';

export const vendorsService = {
    getVendorStats,
    getVendorStatuses,
    changeVendorStatus
};

function getVendorStats(globalFilter){
    return config.live ? getVendorStatsLive(globalFilter) : getVendorStatsStatic();
}


function getVendorStatsLive(globalFilter) {
    
    let baseUrl = config.apis.v3_data,
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };
        
    baseUrl += '/data_admin?' + Param(qs);
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function getVendorStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/data_admin.json`;
    return fetch(baseUrl, {
        method: 'GET'
    })
    .then(res=>{   
        return res.json()
    })
}

function getVendorStatuses() {
    const baseUrl = `${config.apis.orthus}/v1/data_admin_records`,
        requestOptions = {
            method: 'GET',
            headers: AuthHeader()
        };        
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}

function changeVendorStatus(params) {
    const baseUrl = `${config.apis.orthus}/v1/data_admin_records`,
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'}),            
            body: JSON.stringify(params)
        };        
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    })
}