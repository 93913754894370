//import $ from 'jquery';
import Param from '../../helpers/Param';
import AuthHeader from '../../helpers/AuthHeader';
import BuildQS from '../../helpers/BuildQS';
import BuildFilterQS from '../../helpers/BuildFilterQS';
import config from '../../config/config';
import {searchService} from './search';

export const projectsService = {
    getProjects,
    getTopProjects,
    getProjectsOld,
    getProjectsAutocomplete,
    getCard,
    getStreamsStats,
    getProjectsByBarcode,
    getTracks,
    getMetadata,
    getMetadataByIDs,
    getTimeseriesProjects,
    compareProjects,
    compareProjectDemographics,
    compareProjectTerritories,
    compareProjectVendors,
    compareProjectArtist
};

function getProjects(params, globalFilter, basic){
    return config.live ? getProjectsNew(params, globalFilter, basic) : getProjectsStatic(basic);
}

function getProjectsByBarcode(barcodes){
  const options = {
    method: 'GET',
    headers: AuthHeader()
  }
  const params = barcodes.endsWith(',') ? barcodes.replace(/\,+$/, '') : barcodes;
  const url = `${config.apis.miniCat}/v2/projects/by_barcode/${params}`;
  return fetch(url, options)
  .then( response => response.json())
  .catch( error => Promise.reject(error));
}

function getProjectsOld(params) {
    let { sorted, page, pageSize, filtered } = params;
    const requestOptions = {
        method: 'GET',
        headers: AuthHeader()
    };

    // TODO: API dependent, change for prod
    let baseUrl = `${config.apis.sales}/api/v1/Project`,
        qs = {};

    if(sorted !== undefined)
        qs.sort = {[sorted[0].id]: sorted[0].desc?-1:1};
    if(page !== undefined && pageSize !== undefined ) {
        qs.limit = pageSize;
        qs.skip = pageSize*params.page;
    }
    if(filtered !== undefined ) {
        let filters = [];
        for(let filter of filtered){
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array){
                if(value.length)
                    filters.push(`"${filter.id}": {"$in": ["${value.join('","')}"]}`);
            }
            else if(typeof value === 'string')
                filters.push(`"${filter.id}": {"$regex": "${value}", "$options": "i"}`);
            else
                filters.push(`"${filter.id}": ${value}`);

        }
        if(filters.length)
            qs.query = `{${filters.join(',')}}`;
    }
    //
    baseUrl += '?' + Param(qs);

    let pages;

    return fetch(baseUrl, requestOptions)
    .then(res=>{
        pages = res.headers.get('X-Total-Count');
        return res.json()
    })
    .then(data=>{
        return {data, pages}
    });
}


function getTopProjects(params, globalFilter, fields){
    return config.live ? getTopProjectsLive(params, globalFilter, fields) : getTopProjectsStatic();
}

function getTopProjectsLive(params, globalFilter, fields) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };

    qs.query_name = 'top_projects';
    // qs.offset = 0;

    let {sorted, filtered} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;
            }
        }
    }
    
    if(fields) {
        if(Array.isArray(fields))
            fields = fields.join(',');
        qs.fields = fields;
    }

    qs = BuildFilterQS(qs, 'projects');
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}



function getTopProjectsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_projects_simple.json`;
    return fetch(baseUrl).then(res=>res.json());
}
/*
function getTopProjectsStatic() {
    return Promise.resolve(topProjects);    
}
*/

function getProjectsNew(params, globalFilter, basic = true) {
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };

    qs.query_name = `cat_nav_projects${basic?'_basic':''}`;
    qs.offset = params.page ? params.page*globalFilter.limit : 0;
    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else if(typeof value === 'string') { // TODO: kinda crappy way of determining full-string search
                qs.search_by = filter.id;
                qs.search_term = filter.value;
            }
            else {
                qs[filter.id] = value;
            }
        }
    }
    qs = BuildFilterQS(qs, 'projects');
    baseUrl += '?' + $.param(qs);
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        return res.json()
    })
}

function getProjectsStatic(params, basic) {
    let baseUrl = `${config.apis.sales}/api/static/projects${basic?'_basic':''}.json`;
    return fetch(baseUrl, {})
    .then(res=>{
        return res.json()
    });
}

function getProjectsAutocomplete(input) {
    return searchService.autocomplete(input, ['projects']);
}

function getCard(id) {
    let fields = 'id,name,logo_file_name',
        baseUrl = `${config.apis.miniCat}/v3/projects/${id}?fields=${fields}`,
        requestOptions = {
            headers: AuthHeader()
        };
    return fetch(baseUrl, requestOptions)
    .then(res=>{
        return res.json()
    });
}

function getStreamsStats(ids, filtered, globalFilter) {
    return config.live ? getStreamsStatsLive(ids, filtered, globalFilter) : getStreamsStatsStatic();
}

function getStreamsStatsLive(ids, extFiltered, globalFilter) {

    //
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [...extFiltered, {id:'ids', value:ids}]
    }
    //

    let baseUrl = config.apis.v3_data+'/simple',
    qs = BuildQS(globalFilter),
    requestOptions = {
        headers: AuthHeader()
    };

    qs.query_name = 'top_projects';
    // qs.offset = params.page ? params.page*globalFilter.limit : 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered} = params;
    if(sorted !== undefined && sorted.length){
        sorted = sorted[0];
        qs.order_by = sorted.id;
        qs.order_dir = sorted.desc ? 'desc':'asc';
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;
            }
        }
    }

    baseUrl += '?' + Param(qs).replace(/%2C/g,',');

    return fetch(baseUrl, requestOptions)
    .then(res=>{
        return res.json()
    });
}

function getStreamsStatsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_projects_streams.json`;
    return fetch(baseUrl, {})
    .then(res=>{
        return res.json()
    })
}

function getTracks(project_ids) {
    let baseUrl = `${config.apis.miniCat}/v2/projects/acl_tracks`,
        requestOptions = {
            headers: AuthHeader({'Content-Type': 'application/json'}),
            method: 'POST',
            body: JSON.stringify({project_ids})
        };
        
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });

}

function getMetadata(ids){
    return true || config.live ? getMetadataLive(ids) : getMetadataStatic();
}

function getMetadataLive(ids) {
    //ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.miniCat}/v3/projects/by_id/`,
        requestOptions = {
            method: 'POST',
            headers: AuthHeader({'Content-Type': 'application/json'}),
            body: JSON.stringify({project_ids: ids})
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getMetadataStatic() {
    let baseUrl = `${config.apis.sales}/api/static/projects_metadata.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}

function getMetadataByIDs(ids){
    return true || config.live ? getMetadataByIDsLive(ids) : getMetadataByIDsStatic();
}

function getMetadataByIDsLive(ids) {
    ids = encodeURIComponent(ids.join(','));
    
    let baseUrl = `${config.apis.miniCat}/v3/projects/${ids}`,
        requestOptions = {
            method: 'GET',
            headers: AuthHeader({'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'})
        };
    
    return fetch(baseUrl, requestOptions)
    .then(res=>{   
        return res.json()
    });
}

function getMetadataByIDsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/projects_by_id_metadata.json`;
    return fetch(baseUrl, {})
    .then(res=>{   
        return res.json()
    });
}


/*
function getMetadataStatic() {
    return Promise.resolve(projectsMetadata);    
}
*/


function getTimeseriesProjects(params, globalFilter){
    return config.live ? getTimeseriesProjectsLive(params, globalFilter) : getTimeseriesProjectsStatic();
}

function getTimeseriesProjectsLive(params, globalFilter) {
    /*
    const params = {
        sorted:[{id: 'curr_units', 'desc': true}],
        filtered: [{id:'ids', value:ids}]
    }
    */
    
    let baseUrl = config.apis.v3_data+'/simple',
        qs = BuildQS(globalFilter),
        requestOptions = {
            headers: AuthHeader()
        };

    qs.query_name = 'top_projects';
    // qs.offset = 0;
    qs.mode = 'timeseries_ids';

    let {sorted, filtered, dateGroup} = params;
    if(sorted !== undefined){
        qs.order_by = sorted.field;
        qs.order_dir = sorted.dir;
    }

    if(filtered !== undefined && filtered.length){
        for(let filter of filtered) {
            let value = filter.value;
            if(!value)
                continue;
            if(value instanceof Array) {
                value = value.join(',');
                if(!value)
                    continue;
                qs[filter.id] = value;
            }
            else {
                qs[filter.id] = value;
            }
        }
    }
    if(dateGroup) {
        qs.date_grouping = dateGroup;
    }


    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function getTimeseriesProjectsStatic() {
    let baseUrl = `${config.apis.sales}/api/static/top_projects_timeseries.json`;
    return fetch(baseUrl).then(res=>res.json());
}

/*
function getTimeseriesProjectsStatic() {
    return Promise.resolve(topProjectsTimeline);
}
*/

function compareProjects(ids, weeks, releaseDates, territories, vendors, user){
    return config.live ? compareProjectsLive(ids, weeks, releaseDates, territories, vendors, user) : compareProjectsStatic();
}

function compareProjectsLive(ids, weeks, releaseDates, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = Array.isArray(ids) ? ids.join(',') : ids;
    qs.release_dates = Array.isArray(releaseDates) ? releaseDates.join(',') : releaseDates;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'main';
    qs.object = 'project';
    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareProjectsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/project_comparison.json`;
    return fetch(baseUrl).then(res=>res.json());
}

/*
function compareProjectsStatic() {
    return Promise.resolve(comparisonMainData);
}
*/

function compareProjectDemographics(id, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareProjectDemographicsLive(id, weeks, releaseDate, territories, vendors, user) : compareProjectDemographicsStatic();
}

function compareProjectDemographicsLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = id;
    qs.release_dates = releaseDate;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'demographics';
    qs.object = 'project';

    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareProjectDemographicsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/project_comparison_demographics.json`;
    return fetch(baseUrl).then(res=>res.json());
}

/*
function compareProjectDemographicsStatic() {
    return Promise.resolve(comparisonDemographicsData);
}
*/


function compareProjectTerritories(id, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareProjectTerritoriesLive(id, weeks, releaseDate, territories, vendors, user) : compareProjectTerritoriesStatic();
}

function compareProjectTerritoriesLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = id;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.release_dates = releaseDate;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'territories';
    qs.object = 'project';
    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareProjectTerritoriesStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/project_comparison_territories.json`;
    return fetch(baseUrl).then(res=>res.json());
}


function compareProjectVendors(id, weeks, releaseDate, territories, vendors, user){
    return config.live ? compareProjectVendorsLive(id, weeks, releaseDate, territories, vendors, user) : compareProjectVendorsStatic();
}

function compareProjectVendorsLive(id, weeks, releaseDate, territories, vendors, user) {
    let baseUrl = config.apis.v3_data+'/track-comparison',
        qs = {},
        requestOptions = {};

    qs.ids = id;
    qs.weeks = weeks;
    qs.territories = territories;
    qs.vendors = vendors;
    qs.release_dates = releaseDate;
    qs.user_id = user.id;
    qs.client_admin = user.client_admin;
    qs.silo_code = user.primary_silo_code;
    qs.entity = 'vendors';
    qs.object = 'project';
    baseUrl += '?' + Param(qs);
    return fetch(baseUrl, requestOptions)
        .then(res=>res.json());
}

function compareProjectVendorsStatic() { 
    let baseUrl = `${config.apis.sales}/api/static/project_comparison_vendors.json`;
    return fetch(baseUrl).then(res=>res.json());
}

function compareProjectArtist(id, artistID, weeks, releaseDate, territories, vendors, user){
  return config.live ? compareProjectArtistLive(id, artistID, weeks, releaseDate, territories, vendors, user) : compareProjectArtistStatic();
}

function compareProjectArtistLive(id, artistID, weeks, releaseDate, territories, vendors, user) {
  let baseUrl = config.apis.v3_data+'/track-comparison',
    qs = {},
    requestOptions = {};

  qs.ids = id;
  qs.artist_id = artistID;
  qs.weeks = weeks;
  qs.territories = territories;
  qs.vendors = vendors;
  qs.release_dates = releaseDate;
  qs.user_id = user.id;
  qs.client_admin = user.client_admin;
  qs.silo_code = user.primary_silo_code;
  qs.entity = 'album_artist';
  qs.object = 'project';
  baseUrl += '?' + Param(qs);
  return fetch(baseUrl, requestOptions)
    .then(res=>res.json());
}

function compareProjectArtistStatic() { 
  let baseUrl = `${config.apis.sales}/api/static/album_artist.json`;
  return fetch(baseUrl).then(res=>res.json());
}
